@import "swiper/swiper.min.css";
@import "swiper/components/pagination/pagination.min.css";
@import "swiper/components/navigation/navigation.min.css";
@import "aos/dist/aos.css";

/* ===================================== APP SCREENSHOTS AREA ===================================== */
.swiper-slide .slider-image .preview-icon {
	z-index: -1;
	width: calc(100% - 30px);
}
.preview-icon {
	background-color: rgba(0, 0, 0, 0.85);
	height: 100%;
	left: 0;
	opacity: 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	position: absolute;
	right: 0;
	text-align: center;
	top: 0;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	width: 100%;
	margin: 0 auto;
}
.preview-icon i {
	background-color: rgba(255, 255, 255, 0.2);
	border-radius: 70px;
	color: #fff;
	font-size: 30px;
	height: 70px;
	left: 0;
	line-height: 70px;
	margin: 0 auto;
	position: absolute;
	right: 0;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	width: 70px;
}
.swiper-container {
	width: 100%;
	padding-bottom: 20px;
}
.swiper-slide {
	width: 390px;
	height: auto;
	padding: 0 15px;
}
.swiper-slide img {
	/* box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15); */
	/* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
		rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; */
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
		rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.swiper-slide .slider-image .preview-icon {
	z-index: -1;
	width: calc(100% - 30px);
}
.swiper-slide.swiper-slide-active .slider-image:hover .preview-icon {
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	z-index: 1;
}
.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
	bottom: 0;
}
.swiper-pagination-bullet {
	background: transparent none repeat scroll 0 0;
	border: 1px solid #3ad7df;
	border-radius: 100%;
	display: inline-block;
	height: 12px;
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	width: 12px;
}
.swiper-pagination-bullet-active {
	background: rgba(0, 0, 0, 0) -webkit-linear-gradient(
			left,
			#00c6ff 0%,
			#0072ff 100%
		) repeat scroll 0 0;
	background: rgba(0, 0, 0, 0)
		linear-gradient(to right, #00c6ff 0%, #0072ff 100%) repeat scroll 0 0;
	border: medium none;
	height: 12px;
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	width: 12px;
}
.heading {
	color: "yellow";
	font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
		"Lucida Sans Unicode", Geneva, Verdana, sans-serif;
	font-weight: 900;
}
.mySwiperNew.swiper-container {
	width: 100%;
	height: 100%;
	margin-left: auto;
	margin-right: auto;
	margin-top: 5px;
}

.mySwiperNew > .swiper-wrapper > .swiper-slide {
	padding: 0px 0 0 0 !important;
	text-align: center;
	font-size: 18px;
	background: #fff;
	height: calc((100% - 30px) / 2);

	/* Center slide text vertically */
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}

@keyframes fadeInLeft {
	0% {
		opacity: 0;
		transform: translateX(-20px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

.fadeInLeft {
	-webkit-animation-name: fadeInLeft;
	animation-name: fadeInLeft;
}

.animate__bounceIn {
	display: inline-block;
	margin: 0 0.5rem;

	animation: bounceIn; /* referring directly to the animation's @keyframe declaration */
	animation-duration: 2s; /* don't forget to set a duration! */
	animation-delay: 0.2s;
}
.animate__fadeInLeft {
	visibility: visible;
	animation-duration: 2s;
	animation-delay: 0.4s;
	animation-name: fadeInLeft;
}
.animate__fadeInRight {
	visibility: visible;
	animation-duration: 2s;
	animation-delay: 0.4s;
	animation-name: fadeInRight;
}
.animate__fadeIn {
	visibility: visible;
	animation-duration: 2s;
	animation-delay: 0.4s;
	animation-name: fadeIn;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
	appearance: textfield;
}
.ReactPasswordStrength {
	border: none !important;
}
.ReactPasswordStrength-strength-desc {
	right: 0 !important;
	left: 100% !important;
	margin-top: 2px;
	font-size: 15px !important;
}
@media (max-width: 400px) {
	.ReactPasswordStrength-strength-desc {
		font-size: 8px !important;
	}
}
.css-e4w4as-MuiFormLabel-root-MuiInputLabel-root {
	position: absolute;
	top: -8px;
}
.css-cio0x1-MuiInputBase-root-MuiFilledInput-root:before {
	border-bottom: 0 !important;
}
.css-cio0x1-MuiInputBase-root-MuiFilledInput-root:after {
	border-bottom: 0 !important;
}
.css-10i04qz-MuiInputBase-root-MuiFilledInput-root:before {
	border-bottom: 0 !important;
}
.css-10i04qz-MuiInputBase-root-MuiFilledInput-root:after {
	border-bottom: 0 !important;
}
.css-sgvi9r-MuiInputBase-root-MuiFilledInput-root:before {
	border-bottom: 0 !important;
}
.css-sgvi9r-MuiInputBase-root-MuiFilledInput-root:after {
	border-bottom: 0 !important;
}
.css-1w8yruj-MuiInputBase-root-MuiFilledInput-root:before {
	border: 0 !important;
}
.css-1w8yruj-MuiInputBase-root-MuiFilledInput-root:after {
	border: 0 !important;
}
.reactpasswordstrength-input {
	padding: 0;
}
.ReactPasswordStrength-strength-bar {
	/* border: 1px solid white; */
	box-sizing: border-box;
	height: 5px !important;
	position: relative;
	top: 5px !important;
	right: -5px;
	transition: width 300ms ease-out;
	width: 0;
}
.ReactPasswordStrength.is-strength-0 .ReactPasswordStrength-strength-bar {
	background: #d1462f;
	width: 10%;
}

.ReactPasswordStrength.is-strength-1 .ReactPasswordStrength-strength-bar {
	background: #d1462f;
	width: 20%;
}

.ReactPasswordStrength.is-strength-2 .ReactPasswordStrength-strength-bar {
	background: #74ff92;
	width: 40%;
}

.ReactPasswordStrength.is-strength-3 .ReactPasswordStrength-strength-bar {
	background: #35af4f;
	width: 70%;
}

.ReactPasswordStrength.is-strength-4 .ReactPasswordStrength-strength-bar {
	background: #16ff21;
	width: calc(94% + 2px);
}
.ReactPasswordStrength.is-strength-0 .ReactPasswordStrength-strength-desc {
	color: #d1462f;
}
.ReactPasswordStrength.is-strength-1 .ReactPasswordStrength-strength-desc {
	color: #d1462f;
}
.ReactPasswordStrength.is-strength-2 .ReactPasswordStrength-strength-desc {
	color: #74ff92;
}
.ReactPasswordStrength.is-strength-3 .ReactPasswordStrength-strength-desc {
	color: #35af4f;
}
.ReactPasswordStrength.is-strength-4 .ReactPasswordStrength-strength-desc {
	color: #16ff21;
}

/*------------------ navbar active css ----------------*/
a.active {
	color: white;
	border: 2px solid white;
	border-radius: 50px 50px;
}
a:hover {
	border: 2px solid white;
	border-radius: 50px;
}
