
.scrollImg {
    height: auto;
    width: 100%;
    position: absolute;
    top: 0;
    animation-name: scrollingimg;
    animation-duration: 10s;
}

.scrollImg:hover {
    position: absolute;
    top: auto;
    bottom: 0;

}

@keyframes scrollingimg {
    from {
        position: absolute;
        top: 0;
        bottom: auto;
    }
    to {
        position: absolute;
        top: auto;
        bottom: 0;
    }
}

